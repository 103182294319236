import React, { useState, useEffect } from 'react';

export default function Standings() {
  const conferenceNames = {
    0: 'Eastern Conference',
    1: 'Western Conference',
  };

  // We have four divisions total. The keys here match "confId-divId".
  const divisionNames = {
    '0-0': 'Atlantic Division',
    '0-1': 'Metropolitan Division',
    '1-0': 'Pacific Division',
    '1-1': 'Central Division',
  };

  const [teamRecords, setTeamRecords] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [standings, setStandings] = useState([]);
  const [viewMode, setViewMode] = useState('League');
  // possible values: "League", "Conference", or "Division"

  // ------------------------------------
  // 1) Fetch/parse data from your server
  // ------------------------------------
  useEffect(() => {
    async function fetchData() {
      try {
        const recordsResponse = await fetch('/api/upload/fihl/team-records');
        const recordsData = await recordsResponse.json();

        const teamsResponse = await fetch('/api/upload/fihl/team-data');
        const teamsData = await teamsResponse.json();

        // Filter by League Id == 0
        const filteredRecords = recordsData.filter(item => item['League Id'] === 0);
        const filteredTeams = teamsData.filter(item => item['LeagueId'] === 0);

        setTeamRecords(filteredRecords);
        setTeamData(filteredTeams);

      } catch (error) {
        console.error('Error loading CSV data:', error);
      }
    }

    fetchData();
  }, []);

  // ----------------------------------------------
  // 2) Merge data (team_records + team_data) & sort
  // ----------------------------------------------
  useEffect(() => {
    if (teamRecords.length === 0 || teamData.length === 0) {
      setStandings([]);
      return;
    }

    // Merge by matching Team Id
    const merged = teamRecords.map(record => {
      const matchingTeamData = teamData.find(team => team.TeamId === record['Team Id']);
      return {
        teamId: record['Team Id'],
        leagueId: record['League Id'],
        conferenceId: record['Conf Id'],
        divisionId: record['Div Id'],
        wins: record['Wins'],
        losses: record['Losses'],
        ties: record['Ties'],
        otl: record['OTL'],
        shootoutWins: record['Shootout Wins'],
        shootoutLosses: record['Shootout Losses'],
        points: record['Points'],
        goalsFor: record['Goals For'],
        goalsAgainst: record['Goals Against'],
        pct: record['PCT'],
        // From team_data
        name: matchingTeamData?.Name || 'Unknown',
        nickname: matchingTeamData?.Nickname || '',
        primaryColor: matchingTeamData?.['Primary Color'] || '#ccc',
      };
    });

    // Sort by Points descending
    merged.sort((a, b) => Number(b.points) - Number(a.points));
    setStandings(merged);
  }, [teamRecords, teamData]);

  // -----------------------------------
  // Utility: grouping the standings data
  // -----------------------------------
  const groupByConference = (data) => {
    // returns { confId: [array of teams], ... }
    return data.reduce((acc, team) => {
      const key = team.conferenceId;
      if (!acc[key]) acc[key] = [];
      acc[key].push(team);
      return acc;
    }, {});
  };

  const groupByConfDivision = (data) => {
    // combine the two IDs into one string key, e.g. "0-1", "1-0"
    return data.reduce((acc, team) => {
      const key = `${team.conferenceId}-${team.divisionId}`;
      if (!acc[key]) acc[key] = [];
      acc[key].push(team);
      return acc;
    }, {});
  };

  // ------------------------------------------
  // 3) Render table(s) based on the view mode
  // ------------------------------------------
  const renderTableHeader = () => (
    <thead>
      <tr style={{ textAlign: 'left' }}>
        <th style={{ padding: '8px' }}>Team</th>
        <th style={{ padding: '8px' }}>W</th>
        <th style={{ padding: '8px' }}>L</th>
        <th style={{ padding: '8px' }}>OTL</th>
        <th style={{ padding: '8px' }}>PTS</th>
        <th style={{ padding: '8px' }}>GF</th>
        <th style={{ padding: '8px' }}>GA</th>
        <th style={{ padding: '8px' }}>Diff</th>
        <th style={{ padding: '8px' }}>PCT</th>
      </tr>
    </thead>
  );

  const renderTableRows = (data) => {
    return data.map((team) => (
      <tr key={team.teamId} style={{ backgroundColor: team.primaryColor, color: '#fff' }}>
        <td style={{ padding: '8px' }}>
          {team.name} {team.nickname}
        </td>
        <td style={{ padding: '8px' }}>{team.wins}</td>
        <td style={{ padding: '8px' }}>{team.losses}</td>
        <td style={{ padding: '8px' }}>{team.otl}</td>
        <td style={{ padding: '8px' }}>{team.points}</td>
        <td style={{ padding: '8px' }}>{team.goalsFor}</td>
        <td style={{ padding: '8px' }}>{team.goalsAgainst}</td>
        <td style={{ padding: '8px' }}>{team.goalsFor - team.goalsAgainst}</td>
        <td style={{ padding: '8px' }}>{team.pct}</td>
      </tr>
    ));
  };

  // League view = single table
  const renderLeagueView = () => {
    return (
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '1rem' }}>
        {renderTableHeader()}
        <tbody>
          {renderTableRows(standings)}
        </tbody>
      </table>
    );
  };

  // Conference view = multiple tables, one for each conference
  const renderConferenceView = () => {
    const grouped = groupByConference(standings);
    // re-sort within each group
    Object.keys(grouped).forEach((confId) => {
      grouped[confId].sort((a, b) => Number(b.points) - Number(a.points));
    });

    return (
      <div>
        {Object.keys(grouped).map((confId) => (
          <div key={confId} style={{ marginBottom: '2rem' }}>
            <h2>{conferenceNames[confId]} Standings</h2>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
              {renderTableHeader()}
              <tbody>
                {renderTableRows(grouped[confId])}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    );
  };

  // Division view = multiple tables, but grouped by combined "conferenceId-divisionId"
  const renderDivisionView = () => {
    const grouped = groupByConfDivision(standings);
    Object.keys(grouped).forEach((key) => {
      grouped[key].sort((a, b) => Number(b.points) - Number(a.points));
    });

    return (
      <div>
        {Object.keys(grouped).map((key) => (
          <div key={key} style={{ marginBottom: '2rem' }}>
            <h2>{divisionNames[key] || `Division ${key}`} Standings</h2>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0.5rem' }}>
              {renderTableHeader()}
              <tbody>
                {renderTableRows(grouped[key])}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    );
  };

  // ---------------------------------
  // 4) Main return / UI
  // ---------------------------------
  return (
    <div style={{ padding: '1rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1>Standings</h1>

        {/* The dropdown to change between League / Conference / Division */}
        <select
          value={viewMode}
          onChange={(e) => setViewMode(e.target.value)}
          style={{ padding: '0.5rem' }}
        >
          <option value="League">League</option>
          <option value="Conference">Conference</option>
          <option value="Division">Division</option>
        </select>
      </div>

      {/* Conditionally render the appropriate table(s) */}
      {viewMode === 'League' && renderLeagueView()}
      {viewMode === 'Conference' && renderConferenceView()}
      {viewMode === 'Division' && renderDivisionView()}
    </div>
  );
}
